<template>
  <div>
    <div class="section-header">
      <div class="row">
        <div class="col-12">
          <v-toolbar flat>
            <!-- <v-toolbar-title>{{ $t("title.app_list") }}</v-toolbar-title> -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="params.search"
              append-icon="mdi-magnify"
              :label="$t('input.search')"
              single-line
              hide-details
              clearable
              @input="fetchListApp(params)"
              class="mr-5"
            />

            <v-btn
              color="error"
              @click="dialogDelete = !dialogDelete"
              :disabled="dataDelete.length < 1"
              v-if="checkUserPermission('DELETE')"
            >
              {{ $t("button.delete_all") }}
            </v-btn>
            <v-btn
              class="ml-5"
              color="primary"
              @click="createDialog"
              v-if="checkUserPermission('CREATE', userPermission)"
            >
              {{ $t("button.create") }}
            </v-btn>
            <dialog-delete
              :dialog-delete="dialogDelete"
              :darkmode="darkmode"
              v-on:closeDelete="closeDelete"
              v-on:deleteItemConfirm="deleteItemConfirm"
            />
          </v-toolbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DialogDelete from "../../../components/shared/notify/DialogDelete";

export default {
  components: { DialogDelete },
  props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async deleteItemConfirm() {
      await this.deleteApp({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      await this.fetchListApp(this.params);
      this.dialogDelete = false;
    },
    async createDialog() {
      this.fetchPopUp(true);
    },
    ...mapActions("$_configApp", ["fetchPopUp", "fetchListApp", "deleteApp"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
